.responsive-element {
  display: inline-block;
}
/* Media query for small screens */
@media (max-width: 768px) {
  .responsive-element {
    display: block; /* Change to block or any other display property you need */
  }
}
.navbar-nav {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .navbar-nav {
    display: flex;
    flex-direction: column;
  }
}
.navbar-header {
  flex-grow: 1;
}
.nav>li {
  display: inline-block;
  white-space: normal;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}